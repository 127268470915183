import React from 'react'
import About from "../components/About/About"
import Advantages from "../components/Advantages/Advantages"
import Appeal from "../components/Appeal/Appeal"
import Footer from "../components/Footer/Footer"
import StepsWithIcons from "../components/StepsWithIcons/StepsWithIcons"

const HomePage = () => {
  return (
    <>
      <StepsWithIcons />
      <Appeal />
      <Advantages />
      <About />
      <Footer />
    </>
  )
}

export default HomePage