import styled, {css} from "styled-components/macro";
import logo from "./../../assets/svg/digital_bank_logo.png";
import check from "./../../assets/svg/language_check.svg";
import hamburgerMenu from "./../../assets/svg/hamburger_menu.svg";
import bell from "./../../assets/svg/bell.svg";

export const NavbarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 16.2vh;
  background-color: #D8E2FA66;
  font-family: Montserrat;
  @media (max-width: 768px) {
    width: 100vw;
    height: 10vh;
    padding: 0 3vh;
  }
`;

export const NavbarHamburgerMenu = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    width: 2.4vh;
    height: 2.4vh;
    background-image: url(${hamburgerMenu});
    background-repeat: no-repeat;
    background-size: 2.4vh 2.4vh;
  }
`;

export const NavbarLogoSection = styled.div`
  flex-grow: 1.5;
  height: 7vh;
  // >* {
    position: relative;
    // left: 12.5vh;
    width: 16vh;
    height: 7vh;
    background-image: url(${logo});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16vh 7vh;
    background-color: transparent;
    border-radius: 0;
    cursor: pointer;
    @media (max-width: 768px) {
      left: 0;
      width: 16vh;
      background-size: 12vh 5vh;
      margin: auto;
    // }
  }
`;

export const NavbarMenuSection = styled.div`
  flex-grow: 2;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const NavbarSignUpSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1.5;
  gap: 3.4vh;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10vh;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const NavbarNotificationBell = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    width: 2.4vh;
    height: 2.4vh;
    background-image: url(${bell});
    background-repeat: no-repeat;
    background-size: 2.4vh 2.4vh;
  };
`;

// export const NavbarLogo = styled.div`
//   position: relative;
//   left: 12.5vh;
//   width: 16vh;
//   height: 6.7vh;
//   background-image: url(${logo});
//   background-repeat: no-repeat;
//   background-position: center;
//   background-size: 16vh 6.7vh;
//   cursor: pointer;
// `;

export const NavbarUl = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 1.8vh;
`;

export const NavbarList = styled.li`
  list-style: none;
  color: #000000;
  cursor: pointer;
  > a {
    display: inline-block;
    width: auto;
    height: auto;
    font-family: Montserrat !important;
    font-weight: 500;
    font-size: 1.8vh;
    text-decoration: none;
    background-color: transparent;
    color: #000000 !important;
    border-radius: 0;
  }
`;

interface INavbarNotificationBadge {
  status: boolean;
}

export const NavbarNotificationBadge = styled.span`
  position: absolute;
  top: 6.5vh;
  display: none;
  width: 1.6vh;
  height: 1.6vh;
  background-color: #ff554d;
  border: 0.05vh solid #0058A0;
  border-radius: 50%;
  margin-left: 0.3vh;
  ${(props: INavbarNotificationBadge) => props.status ?
    css`
      display: inline-block;
    ` : null
  }
`;

export const NavbarLanguage = styled.div`
  font-weight: 500;
  font-size: 1.7vh;
  cursor: pointer;
`;

export const NavbarLanguageIcon = styled.div`
  display: inline-block;
  width: 1vh;
  height: 0.8vh;
  background-image: url(${check});
  background-repeat: no-repeat;
  margin-left: 0.8vh;
`;

export const NavbarSignIn = styled.button`
  height: 4vh;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 1.7vh;
  background-color: transparent;
  color: #0058A0;
  border: 0 solid transparent;
  cursor: pointer;
  overflow: hidden;
  &:after {
    content: "";
    position: relative;
    top: 0.6vh;
    display: block;
    margin: 0 auto;
    width: 2.6vh;
    border-bottom: 0.3vh solid #0058A0CC;
    transform: scaleX(1);
    transition: transform 150ms ease-in-out;
  }
  &:hover:after {
    transform: scaleX(3.5);
  }
`;

export const NavbarSignUp = styled.button`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 1.7vh;
  background-color: #FFFFFF;
  color: #0058A0;
  border: 0 solid transparent;
  border-radius: 2.1vh;
  box-shadow: 0 0.4vh 0.4vh #0000000D;
  padding: 0.8vh 1.1vh;
  cursor: pointer;
`;