import React from 'react'
import Offer from "../components/Offer/Offer"

const OffersPage = () => {
  return (
    <>
      <Offer />
    </>
  )
}

export default OffersPage