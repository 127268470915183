import {useDispatch} from "react-redux";
import {bindActionCreators} from "@reduxjs/toolkit";
import { additionActions } from "../store/additions/additions.slice";

const actions = {
  ...additionActions,
}

export const useActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(actions, dispatch);
}