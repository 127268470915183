import axios from "axios";
import React, { useEffect } from 'react'
import { Route, Routes } from "react-router-dom";
import { endpoints } from "../api/endpoints";
import Navbar from "../components/Navbar/Navbar";
import HomePage from "../pages/HomePage";
import NoPage from "../pages/NoPage";
import OffersPage from "../pages/OffersPage";

const Main = () => {

  useEffect(() => {
    const data = {
      email: "admin@example.com",
      password: "Admin1234@",
    }
    axios
      .post(endpoints.login, data)
      .then((response) => {
        sessionStorage.setItem(
          "credentials",
          JSON.stringify(response.data)
        );

        const AuthStr = "Bearer " + response.data.access_token || "";
        axios
          .get(endpoints.profile.get, {
            headers: { Authorization: AuthStr },
          })
          .then((res) => {
            res.data.email = data.email;
            sessionStorage.setItem(
              "user_details",
              JSON.stringify({
                ...res.data,
                password: data.password,
              })
            );
          })
          .catch((error) => {
            console.log("error " + error);
          });
      })
      .catch((error) => {
        console.log("error " + error);
      });
  }, []);

  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="offers" element={<OffersPage />} />
        <Route path="/*" element={<NoPage />} />
      </Routes>
    </>
  )
}

export default Main