import React, { useEffect, useRef, useState } from 'react'
import { endpoints } from "../../api/endpoints"
import { request } from "../../helpers/request"
import { useActions } from "../../hooks/actions"
import { useAppSelector } from "../../hooks/redux"
import { AppealPrivacyCheckbox } from "../Appeal/Appeal.style"
import { FooterIconContainerForMobile, FooterIconItselfForMobile } from "../Footer/Footer.style"
import Modal from "../Modal/Modal"
import Contract from "./Contract/Contract"
import { OfferAcceptButton, OfferBodyDarkText, OfferBodyHolder, OfferBodyText, OfferButtonSection, OfferContainerPart, OfferContainerPartFlexBox, OfferDeclineButton, OfferIconHolder, OfferMainContainer, OfferPersonFlexBox, OfferPrivacySection, OfferPrivacyText, OfferSubTitleHolder, OfferSubTitleItself, OfferTitle, OfferVersion } from "./Offer.style"

const Offer = () => {

  const {showModal, sendModalName} = useActions();
  const {modalStatus} = useAppSelector(state => state.reducerAdditions);

  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [checkboxError, setCheckboxError] = useState<boolean>(false);
  const [showContract, setShowContract] = useState<boolean>(false);

  const checkboxRef = useRef<HTMLInputElement>(null);

  const [bankOffer, setBankOffer] = useState<any[]>([]);
  const [totalAmount, setTotalAmount] = useState<string>("");
  const [interestRate, setInterestRate] = useState<string>("");
  const [duration, setDuration] = useState<string>("");

  useEffect(() => {
    const customerOrder = {
      "PIN": "00000000/0000",
	    "Amount": 5000,
	    "Period": 24,
	    "MobileNumber": "+49000000000"
    }

    request
      .post(endpoints.bank_offer.post, customerOrder)
      .then(res => {
        // console.log(res.data);

        // const response: any = Object.values<any>(res.data)[1].values;
        const response = res.data.product_1.values;
        setBankOffer(response);
      })
      .catch(error => {
        console.log("error ", error)
      });
  }, []);

  useEffect(() => {
    // console.log(bankOffer);
    if (bankOffer) {
      // bankOffer.map((each: any) => console.log(each));
      bankOffer.find((each: any) => (
        each.attribute === "Main_amount" ? setTotalAmount(() => each.value) :
        each.attribute === "Interest_Rate" ? setInterestRate(() => each.value) :
        each.attribute === "TERM" ? setDuration(() => each.value) : undefined
      ));
    }
  }, [bankOffer]);

  const acceptOfferHandler = () => {
    if(!checkboxRef.current?.defaultChecked) {
      setCheckboxError(true);
    }
    else {
      setCheckboxError(false);
      setShowContract(true);
    }
  }

  const declineOfferHandler = () => {
    sendModalName("decline_appeal");
    showModal(true);
  }

  return (
    <>
      <OfferMainContainer>
        <OfferTitle>{!showContract ? "Offer" : "Contract"}</OfferTitle>
        <OfferContainerPartFlexBox>
          <OfferContainerPart>
            <OfferSubTitleHolder>
              <OfferSubTitleItself>
                Information about person/customer
              </OfferSubTitleItself>
            </OfferSubTitleHolder>
            <OfferBodyHolder>
              <OfferPersonFlexBox>
                <OfferIconHolder />
                <div>
                  <OfferBodyText>
                    Robert James
                  </OfferBodyText>
                  <div style={{position: "relative", bottom: "1.5vh", fontWeight: "400", fontSize: "1.4vh"}}>
                    Customer
                  </div>
                </div>
              </OfferPersonFlexBox>
              <div style={{minWidth: "30vh"}}>
                <OfferBodyText>
                  Date of birth:&nbsp;
                  <OfferBodyDarkText>12/06/1999</OfferBodyDarkText>
                </OfferBodyText>
                <OfferBodyText>
                  Phone:&nbsp;
                  <OfferBodyDarkText>+4900 000 0000</OfferBodyDarkText>
                </OfferBodyText>
                <OfferBodyText>
                  PIN:&nbsp;
                  <OfferBodyDarkText>1A2B3C4</OfferBodyDarkText>
                </OfferBodyText>
              </div>
              <OfferVersion>
                Version: AF20220701/AF001
              </OfferVersion>
            </OfferBodyHolder>
          </OfferContainerPart>

          {showContract &&
            <OfferContainerPart forContract={true}>
              <OfferSubTitleHolder>
                <OfferSubTitleItself>
                  Application ID and date
                </OfferSubTitleItself>
              </OfferSubTitleHolder>
              <OfferBodyHolder>
                
                <div style={{minWidth: "30vh"}}>
                  <OfferBodyText>
                    Application ID:&nbsp;
                    <OfferBodyDarkText>1234567890</OfferBodyDarkText>
                  </OfferBodyText>
                  <OfferBodyText>
                    Date of application:&nbsp;
                    <OfferBodyDarkText>12/06/1999</OfferBodyDarkText>
                  </OfferBodyText>
                </div>
              </OfferBodyHolder>
            </OfferContainerPart>
          }
        </OfferContainerPartFlexBox>

        {!showContract &&
          <>
            <OfferContainerPart>
              <OfferSubTitleHolder>
                <OfferSubTitleItself>
                  Credit order
                </OfferSubTitleItself>
              </OfferSubTitleHolder>
              <OfferBodyHolder>
                <div style={{minWidth: "26vh"}}>
                  <OfferBodyText>
                    Total amount&nbsp;
                    <OfferBodyDarkText>- 2 000</OfferBodyDarkText>
                  </OfferBodyText>
                  <OfferBodyText>
                    Interest rate&nbsp;
                    <OfferBodyDarkText>- 13%</OfferBodyDarkText>
                  </OfferBodyText>
                  <OfferBodyText>
                    Duration&nbsp;
                    <OfferBodyDarkText>- 24 mo</OfferBodyDarkText>
                  </OfferBodyText>
                </div>
                <div style={{minWidth: "30vh"}}>
                  <OfferBodyText>
                    Total monthly annuity&nbsp;
                    <OfferBodyDarkText>- 000 eur</OfferBodyDarkText>
                  </OfferBodyText>
                </div>
                <div style={{minWidth: "26vh"}}>
                  <OfferBodyText>
                    Application ID:&nbsp;
                    <OfferBodyDarkText>1234567890</OfferBodyDarkText>
                  </OfferBodyText>
                  <OfferBodyText>
                    Date of application:&nbsp;
                    <OfferBodyDarkText>12/06/1999</OfferBodyDarkText>
                  </OfferBodyText>
                </div>
              </OfferBodyHolder>
            </OfferContainerPart>

            <OfferContainerPart bankOrder={true}>
              <OfferSubTitleHolder bankOrder={true}>
                <OfferSubTitleItself>
                  Bank Offer
                </OfferSubTitleItself>
                <FooterIconItselfForMobile text="approve" />
              </OfferSubTitleHolder>
              <OfferBodyHolder>
                <div style={{minWidth: "26vh"}}>
                  <OfferBodyText>
                    Total amount&nbsp;
                    <OfferBodyDarkText>- {totalAmount ? JSON.parse(totalAmount.replace(/'/g, '"')).from : 0}</OfferBodyDarkText>
                  </OfferBodyText>
                  <OfferBodyText>
                    Interest rate&nbsp;
                    <OfferBodyDarkText>- {interestRate ? JSON.parse(interestRate.replace(/'/g, '"')).from + " %" : 0}</OfferBodyDarkText>
                  </OfferBodyText>
                  <OfferBodyText>
                    Duration&nbsp;
                    <OfferBodyDarkText>- {duration ? JSON.parse(duration.replace(/'/g, '"')).from + " mo" : 0}</OfferBodyDarkText>
                  </OfferBodyText>
                </div>
                <div style={{display: "none", minWidth: "30vh"}}>
                  <OfferBodyText>
                    Monthly annuity&nbsp;
                    <OfferBodyDarkText>- 000 eur</OfferBodyDarkText>
                  </OfferBodyText>
                </div>
              </OfferBodyHolder>
            </OfferContainerPart>

            <OfferPrivacySection>
              <AppealPrivacyCheckbox
                ref={checkboxRef}
                type="checkbox"
                error={checkboxError}
                defaultChecked={isChecked}
                onChange={() => setIsChecked(!isChecked)}
              />
              <OfferPrivacyText error={checkboxError}>I accept the credit terms</OfferPrivacyText>
            </OfferPrivacySection>

            <OfferButtonSection>
              <OfferDeclineButton onClick={() => declineOfferHandler()}>Refuse</OfferDeclineButton>
              <OfferAcceptButton onClick={() => acceptOfferHandler()}>Accept</OfferAcceptButton>
            </OfferButtonSection>
          </>
        }
        
        {showContract && <Contract />}

      </OfferMainContainer>
      <FooterIconContainerForMobile>
        <FooterIconItselfForMobile text="home" />
        <FooterIconItselfForMobile text="payment" />
        <FooterIconItselfForMobile text="person" />
        <FooterIconItselfForMobile text="location" />
        <FooterIconItselfForMobile text="settings" />
      </FooterIconContainerForMobile>

      {modalStatus && <Modal />}
    </>
  )
}

export default Offer