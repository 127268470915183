import styled, { css } from "styled-components/macro";
import logo from "./../../assets/svg/digital_bank_logo.png";
import twitter from "./../../assets/svg/twitter.svg";
import facebook from "./../../assets/svg/facebook.svg";
import mail from "./../../assets/svg/mail.svg";
import linkedin from "./../../assets/svg/linkedin.svg";
import home from "./../../assets/svg/home.svg";
import payment from "./../../assets/svg/payment.svg";
import person from "./../../assets/svg/person.svg";
import location from "./../../assets/svg/location.svg";
import settings from "./../../assets/svg/settings.svg";
import approve from "./../../assets/svg/approve.svg";

export const FooterContainer = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 21.9vh;
  background-color: #D8E2FA66;
  font-family: Montserrat;
  @media (max-width: 768px) {
    height: 10.3vh;
    padding: 0 2vh;
    margin-bottom: 6.3vh;
  }
`;

export const FooterLogoSection = styled.div`
  flex-grow: 1.5;
`;

export const FooterMenuSection = styled.div`
  flex-grow: 2;
  padding-right: 10vh;
`;

export const FooterSocialMediaSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1.5;
  margin-top: 5.8vh;
  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

export const FooterLogo = styled.div`
  position: relative;
  left: 12.5vh;
  width: 16vh;
  height: 6.7vh;
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16vh 6.7vh;
  @media (max-width: 768px) {
    left: 0;
    width: 9.55vh;
    height: 4vh;
    background-size: 9.55vh 4vh;
  }
`;

export const FooterUl = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 1.8vh;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const FooterList = styled.li`
  list-style: none;
  color: #000000;
  cursor: pointer;
`;

export const FooterSocialMediaPart = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1.8vh;
  margin-bottom: 4.1vh;
  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;

export const FooterSocialMediaIconHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3vh;
  height: 3vh;
  background-color: #FFFFFF;
  border: 0.1vh solid #0058A080;
  border-radius: 50%;
  cursor: pointer;
`;

interface IFooterSocialMediaIconItself {
  text: string;
}

export const FooterSocialMediaIconItself = styled.div`
  background-repeat: no-repeat;
  ${(props: IFooterSocialMediaIconItself) => props.text === "twitter" ?
    css`
      width: 1.6vh;
      height: 1.3vh;
      background-image: url(${twitter});
    ` : props.text === "facebook" ?
    css`
      width: 0.7vh;
      height: 1.4vh;
      background-image: url(${facebook});
    ` : props.text === "mail" ?
    css`
      width: 1.44vh;
      height: 1vh;
      background-image: url(${mail});
    ` : props.text === "linkedin" ?
    css`
      width: 1.2vh;
      height: 1.2vh;
      background-image: url(${linkedin});
    ` : null
  }
`;

export const FooterCopyRightText = styled.p`
  display: flex;
  justify-content: center;
  font-family: Montserrat !important;
  font-weight: 400;
  font-size: 1.2vh;
  margin: 0 !important;
  padding-left: 2vh;
  @media (max-width: 768px) {
    position: absolute;
    right: 13vh;
    padding-left: 0;
    margin-top: 4.5vh !important;
  }
`;

export const FooterIconContainerForMobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 6.3vh;
    background-color: #FFFFFF;
    border-top: 0.05vh solid #25398E;
    padding: 0 2vh;
    cursor: pointer;
  };
`;

interface IFooterIconItselfForMobile {
  text: string;
}

export const FooterIconItselfForMobile = styled.div`
  width: 2.4vh;
  height: 2.4vh;
  background-repeat: no-repeat;
  ${(props: IFooterIconItselfForMobile) => props.text === "home" ?
    css`
      background-image: url(${home});
    ` : props.text === "payment" ?
    css`
      background-image: url(${payment});
    ` : props.text === "person" ?
    css`
      background-image: url(${person});
    ` : props.text === "location" ?
    css`
      background-image: url(${location});
    ` : props.text === "settings" ?
    css`
      background-image: url(${settings});
    ` : props.text === "approve" ?
    css`
      background-image: url(${approve});
      background-size: 2vh 2vh;
    ` : null
  }
`;