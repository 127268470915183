import React from 'react'
import { CloseModalButton, ModalAcceptButton, ModalButtonSection, ModalContainer, ModalDarkScreen, ModalDeclineButton, ModalGreenCheckedIcon, ModalInfoText, ModalOfferSerial, ModalProcessingText, ModalTitle } from "./Modal.style"
import "./modal.css";
import { useActions } from "../../hooks/actions";
import { useAppSelector } from "../../hooks/redux";

const Modal = () => {
  const {showModal, sendModalName} = useActions();
  const {modalStatus, modalName} = useAppSelector(state => state.reducerAdditions);

  const closeModalHandler = () => {
    showModal(false);
    sendModalName(null);
  }

  const cancelDeclineOfferHandler = () => {
    showModal(false);
    sendModalName(null);
  }

  return (
    <>{modalStatus &&
        <ModalDarkScreen>
          <ModalContainer text={modalName}>

            {modalName === "appeal_processing" &&
              <>
                <ModalOfferSerial>Appeal ID: A-00001</ModalOfferSerial>
                <ModalGreenCheckedIcon />
                <ModalTitle>Your application has been successfully completed!</ModalTitle>
                <ModalInfoText>Your credit report has been accepted. Your request is being processed and your order will be answered as soon as possible.</ModalInfoText>
                <ModalProcessingText>Processing</ModalProcessingText>
                {/* MODAL SPINNER BELOW */}
                <div className="loader"></div>
                <ModalTitle>Your application is being processed.</ModalTitle>
                <ModalInfoText>Information about you is collected and sent to a decision engine. After all the information is processed, you will see our Bank's offer to you.
      </ModalInfoText>
                <ModalInfoText><span style={{fontWeight: "600", textDecoration: "underline", color: "#25398E"}}>Note:</span> This process may take 30-60 seconds depending on the speed of services received from 3rd parties. You don't have to wait for this service to respond. As soon as the answer is ready, our application will provide you with a notification.</ModalInfoText>
                <CloseModalButton onClick={closeModalHandler}>Close</CloseModalButton>
              </>
            }

            {modalName === "decline_appeal" &&
              <>
                <ModalTitle>Are you sure you want to leave this section?</ModalTitle>
                <ModalButtonSection>
                  <ModalAcceptButton>Yes</ModalAcceptButton>
                  <ModalDeclineButton onClick={() => cancelDeclineOfferHandler()}>No</ModalDeclineButton>
                </ModalButtonSection>
              </>
            }
          </ModalContainer>
        </ModalDarkScreen>
      }
    </>
  )
}

export default Modal