import styled from "styled-components/macro";
import mobilePayment from "./../../assets/svg/mobile_payment.png";

export const AboutSectionContainer = styled.section`
  display: flex;
  flex-direction: row;
  font-family: Montserrat;
  background-color: #0058A0;
  color: #FFFFFF;
  margin-bottom: 18vh;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 2vh 3.8vh 2vh;
    margin-bottom: 3vh;
  }
`;

export const AboutLeft = styled.div`
  width: 53%;
  padding: 11.6vh 12.5vh 10vh 10vh;
  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`;

export const AboutRight = styled.div`
  width: 47%;
  @media (max-width: 768px) {
    order: -1;
    width: 100%;
    margin-bottom: -5vh;
  }
`;

export const AboutTitle = styled.h1`
  font-weight: 500;
  font-size: 4vh;
  margin-bottom: 3.2vh;
  @media (max-width: 768px) {
    font-size: 2.5vh;
    margin-bottom: 1vh;
  }
`;

export const AboutText = styled.p`
  line-height: 2.5vh;
  font-family: Montserrat !important;
  font-weight: 400;
  font-size: 1.6vh;
  margin: 0 0 11.7vh 0 !important;
  @media (max-width: 768px) {
    height: 10vh;
    line-height: 2vh;
    overflow: hidden;
    font-size: 1.2vh;
    padding-right: 3vh;
    margin-bottom: 2.5vh !important;
  }
`;

export const AboutOfferButton = styled.button`
  width: 29.6vh;
  height: 4.5vh;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 1.8vh;
  border: 0 solid transparent;
  border-radius: 0.8vh;
  color: #0058A0;
  cursor: pointer;
  @media (max-width: 768px) {
    display: block;
    width: 25vh;
    font-size: 1.7vh;
    margin: auto;
  }
`;

export const AboutImage = styled.div`
  position: relative;
  bottom: 9vh;
  width: 70vh;
  height: 58vh;
  background-image: url(${mobilePayment});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70vh 58vh;
  @media (max-width: 768px) {
    bottom: 8.4vh;
    width: 31vh;
    height: 27vh;
    float: right;
    background-size: 31vh 27vh;
  }
`;