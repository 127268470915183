import React from 'react'
import { StepIcon, StepIconContainer, StepIconHolder, StepIconIntervalLine, StepIconText, StepsWithIconsContainer } from "./StepsWithIcons.style"

const StepsWithIcons = () => {
  return (
    <>
      <StepsWithIconsContainer>
        <StepIconContainer>
          <StepIconHolder>
            <StepIcon text="appealing" />
          </StepIconHolder>
          <StepIconText>Application</StepIconText>
        </StepIconContainer>
        <StepIconIntervalLine />
        <StepIconContainer>
          <StepIconHolder>
            <StepIcon text="offer" />
          </StepIconHolder>
          <StepIconText>Offers</StepIconText>
        </StepIconContainer>
        <StepIconIntervalLine />
        <StepIconContainer>
          <StepIconHolder>
            <StepIcon text="contract" />
          </StepIconHolder>
          <StepIconText>Contract</StepIconText>
        </StepIconContainer>
        <StepIconIntervalLine />
        <StepIconContainer>
          <StepIconHolder>
            <StepIcon text="payment" />
          </StepIconHolder>
          <StepIconText>Payment</StepIconText>
        </StepIconContainer>
      </StepsWithIconsContainer>
    </>
  )
}

export default StepsWithIcons