import styled, { css } from "styled-components/macro";
import sima from "./../../../assets/svg/sima_white_icon.svg";
import asan from "./../../../assets/svg/asan_white_icon.svg";
import submit from "./../../../assets/svg/document_submit.svg";

export const ContractMainContainer = styled.section`
  display: flex;
  flex-direction: row;
  gap: 12vh;
  margin-top: 10.1vh;
  margin-bottom: 8vh;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 3vh;
    margin-top: 1vh;
    margin-bottom: 3vh;
  }
`;

export const ContractLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (max-width: 768px) {
    border: 0.05vh solid #3A3A3A80;
    border-radius: 1vh;
    padding: 3.1vh 0.2vh;
  }
`;

export const ContractSignatureSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 3.5vh;
  margin-bottom: 5vh;
  @media (max-width: 768px) {
    margin-bottom: 3vh;
  }
`;

export const ContractSignaturePart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20vh;
`;

export const ContractSignatureIconHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7vh;
  height: 7vh;
  border-radius: 50%;
  background-color: #0058A0;
  margin-bottom: 1.5vh;
  cursor: pointer;
`;

interface IContractSignatureIconItself {
  text: string;
}

export const ContractSignatureIconItself = styled.div`
  background-repeat: no-repeat;
  ${(props: IContractSignatureIconItself) => props.text === "sima" ?
    css`
      width: 3vh;
      height: 3vh;
      background-image: url(${sima});
    ` : props.text === "asan" ?
    css`
      width: 4.66vh;
      height: 3vh;
      background-image: url(${asan});
    ` : null
  }
`;

export const ContractSignatureButton = styled.button`
  width: 34.2vh;
  height: 4.5vh;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 1.8vh;
  border: 0 solid transparent;
  border-radius: 0.8vh;
  background-color: #0058A0;
  color: #FFFFFF;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 27.2vh;
  }
`;

export const ContractRight = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContractDocument = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 63.3vh;
  height: 77.2vh;
  font-weight: 500;
  font-size: 2vh;
  background-color: #D9D9D9;
  border-radius: 0.8vh;
  @media (max-width: 768px) {
    width: 100%;
    height: 43.2vh;
  }
`;

export const ContractDownloadIconHolder = styled.div`
  width: 7vh;
  height: 7vh;
  margin-left: 8.3vh;
  cursor: pointer;
  @media (max-width: 768px) {
    margin-left: 1.7vh;
  };
`;

export const ContractDownloadIconItself = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${submit});
  background-repeat: no-repeat;
  background-size: 100% 100%;
`;