export const domain = "https://api.gate.dev.scorport.eu";

export const apiVersion = "/api/v1";

export const endpoints = {
  login: `${domain}/token/`,
  profile: {
    get: `${domain + apiVersion}/users/currentuser/`,
    patch: `${domain + apiVersion}/users/currentuser/`,
  },

  bank_offer: {
    post: `https://api.gate.dev.scorport.eu/api/v1/integrator/services/german_app/engine_app`,
  },
}