import React, { useEffect, useState } from 'react';
import { Routes, Route } from "react-router-dom";
import Main from "./layout/Main";

function App() {
  const viewPort = document.querySelector("meta[name=viewport]");

  const [dimensions, setDimensions] = useState({
    width: window.screen.width,
    height: window.screen.height,
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions(() => ({
        width: window.screen.width,
        height: window.screen.height,
      }))
    }

    window.addEventListener("resize", handleResize);

    viewPort?.setAttribute("content", "width=" + (dimensions.width - 100) + ", height=" + dimensions.height + ", initial-scale=1, user-scalable=no");

    return () => window.removeEventListener("resize", handleResize);
  }, [dimensions, viewPort]);
  
  return (
    <>
      <Routes>
        <Route path={"/*"} element={<Main />}>
        </Route>
      </Routes>
    </>
  );
}

export default App;
