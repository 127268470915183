import styled, {css} from "styled-components/macro";

export const OfferMainContainer = styled.section`
  font-family: Montserrat;
  color: #3A3A3A;
  margin-left: 17.4vh;
  @media (max-width: 768px) {
    margin: 0 2vh 6.3vh 2vh;
    overflow-x: hidden;
  }
`;

export const OfferTitle = styled.h1`
  font-weight: 500;
  font-size: 4vh;
  margin: 2vh 0;
  @media (max-width: 768px) {
    font-size: 2.5vh;
    margin: 1.5vh 0;
  }
`;

export const OfferContainerPartFlexBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2vh;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0;
  }
`;

interface IOfferContainerPart {
  bankOrder?: boolean;
  forContract?: boolean;
}

export const OfferContainerPart = styled.div`
  width: 94.2vh;
  min-height: 21.9vh;
  border: 0.1vh solid #0058A0;
  border-radius: 0.8vh;
  margin-bottom: 2vh;
  ${(props: IOfferContainerPart) => props.bankOrder ?
    css`
      background-color: #0058A0;
      color: #FFFFFF;
    ` : null
  }
  ${(props: IOfferContainerPart) => props.forContract ?
    css`
      width: 38%;
      @media (max-width: 768px) {
        width: 100%;
        min-height: 0;
      }
    ` : null
  }
  @media (max-width: 768px) {
    width: 100%;
  };
`;

export const OfferSubTitleHolder = styled.div`
  border-bottom: 0.1vh solid #0058A0;
  ${(props: IOfferContainerPart) => props.bankOrder ?
    css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 0.1vh solid #FFFFFF !important;
      padding-right: 3.4vh;
    ` : null
  }
`;

export const OfferSubTitleItself = styled.h2`
  font-weight: 500;
  font-size: 2vh;
  margin: 1.3vh 0 1.3vh 4.6vh;
  @media (max-width: 768px) {
    margin-left: 1.8vh;
  }
`;

export const OfferBodyHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2vh;
  padding-top: 2.5vh;
  padding-left: 4.6vh;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0;
    padding: 1.6vh 0.8vh 0 1.8vh;
  }
`;

export const OfferPersonFlexBox = styled.div`
  min-width: 26vh;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const OfferIconHolder = styled.div`
  width: 6vh;
  height: 6vh;
  border-radius: 50%;
  background-color: #D9D9D9;
  margin-bottom: 2vh;
  @media (max-width: 768px) {
    margin-right: 1.5vh;
  }
`;

const BodyText = styled.p`
  font-family: Montserrat !important;
  font-weight: 500;
  font-size: 1.8vh;
  margin: 0 !important;
`;

export const OfferBodyText = styled(BodyText)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2vh !important;
`;

export const OfferBodyDarkText = styled(BodyText)`
  font-weight: 600;
`;

export const OfferVersion = styled.div`
  position: relative;
  top: 10.1vh;
  right: 1.5vh;
  display: flex;
  align-items: center;
  height: 2.6vh;
  font-weight: 400;
  font-size: 1vh;
  letter-spacing: 0.1vh;
  white-space: nowrap;
  background-color: #D9D9D9;
  border-radius: 0.8vh;
  padding: 0.7vh 1.1vh;
  overflow-y: hidden;
  @media (max-width: 768px) {
    position: absolute;
    top: 40.2vh;
    left: 26.5vh;
    width: 36%;
    height: 2.2vh;
    font-size: 0.9vh;
  }
`;

export const OfferPrivacySection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.5vh;
  margin-bottom: 2vh;
`;

// export const OfferPrivacyCheckbox = styled.input`
//   width: 2vh;
//   height: 2vh;
//   cursor: pointer;
// `;

interface IOfferPrivacyText {
  error: boolean;
}

export const OfferPrivacyText = styled.p`
  font-family: Montserrat !important;
  font-weight: 500;
  font-size: 1.6vh;
  margin: 0 !important;
  ${(props: IOfferPrivacyText) => props.error ?
    css`
      color: #ff554d;
    ` : null
  }
`;

export const OfferButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2vh;
  margin-bottom: 3.2vh;
`;

const OfferButton = styled.button`
  width: 27vh;
  height: 4.5vh;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 1.8vh;
  border: 0.1vh solid #0058A0;
  border-radius: 0.8vh;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 1.7vh;
  }
`;

export const OfferDeclineButton = styled(OfferButton)`
  background-color: #FFFFFF;
  color: #0058A0;
`;

export const OfferAcceptButton = styled(OfferButton)`
  background-color: #0058A0;
  color: #FFFFFF;
`;