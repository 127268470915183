import styled, { css } from "styled-components/macro";
import appealing from "./../../assets/svg/appealing.svg";
import offer from "./../../assets/svg/offer.svg";
import contract from "./../../assets/svg/contract.svg";
import payment from "./../../assets/svg/payment.svg";

export const StepsWithIconsContainer = styled.section`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2.4vh;
  font-family: Montserrat;
  margin-top: 6.6vh;
  margin-bottom: 6.3vh;
  margin-left: 4vh;
  @media (max-width: 768px) {
    top: 13vh;
    gap: 1vh;
    margin: 0 2.1vh;
  }
`;

export const StepIconContainer = styled.div`

`;

export const StepIconHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8vh;
  height: 8vh;
  background-color: #25398E14;
  border-radius: 50%;
  @media (max-width: 768px) {
    width: 5vh;
    height: 5vh;
  }
`;

interface IStepIcon {
  text: string;
}

export const StepIcon = styled.div`
  background-repeat: no-repeat;
  ${(props: IStepIcon) => props.text === "appealing" ?
    css`
      width: 3.48vh;
      height: 3.67vh;
      background-image: url(${appealing});
      @media (max-width: 768px) {
        width: 2vh;
        height: 2vh;
      }
    ` : props.text === "offer" ?
    css`
      width: 3.67vh;
      height: 3.66vh;
      background-image: url(${offer});
      @media (max-width: 768px) {
        width: 2vh;
        height: 2vh;
      }
    ` : props.text === "contract" ?
    css`
      width: 3.19vh;
      height: 3.67vh;
      background-image: url(${contract});
      @media (max-width: 768px) {
        width: 2vh;
        height: 2vh;
      }
    ` : props.text === "payment" ?
    css`
      width: 3.67vh;
      height: 3.19vh;
      background-image: url(${payment});
      @media (max-width: 768px) {
        width: 2vh;
        height: 2vh;
      }
    `
    : null
  }
`;

export const StepIconText = styled.p`
  display: flex;
  justify-content: center;
  font-family: Montserrat !important;
  font-weight: 500;
  font-size: 1.7vh;
  @media (max-width: 768px) {
    font-size: 1.2vh;
  }
`;

export const StepIconIntervalLine = styled.div`
  position: relative;
  top: 4vh;
  width: 12.1vh;
  height: 0.1vh;
  background-color: #25398EB2;
  @media (max-width: 768px) {
    top: 2.5vh;
    width: 4.2vh;
  }
`;