import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface IAdditions {
  modalStatus: boolean;
  modalName: string | null;
  badgeStatus: boolean;
}

const initialState: IAdditions = {
  modalStatus: false,
  modalName: null,
  badgeStatus: false,
}

export const additionSlice = createSlice({
  name: "additions",
  initialState,
  reducers: {
    showModal(state, action: PayloadAction<boolean>) {
      state.modalStatus = action.payload;
    },
    sendModalName(state, action: PayloadAction<string | null>) {
      state.modalName = action.payload;
    },
    showBadge(state, action: PayloadAction<boolean>) {
      state.badgeStatus = action.payload;
    },
  }
});

export const additionActions = additionSlice.actions;
export const additionReducer = additionSlice.reducer;