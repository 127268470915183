import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { AppealButton, AppealInputContainer, AppealInputExtraForEuro, AppealInputExtraForPeriod, AppealInputItself, AppealInputLabel, AppealInputLeft, AppealInputRight, AppealLeft, AppealMainContainer, AppealPersonalDataInputContainer, AppealPersonalDataInputExtra, AppealPersonalDataInputField, AppealPersonalDataInputPart, AppealPrivacyCheckbox, AppealPrivacyContainer, AppealPrivacyText, AppealRangeCustomGap, AppealRangeItself, AppealRangeLabel, AppealRangeMeasureLabel, AppealRangeScale, AppealRight, AppealSignatureContainer, AppealSignatureIconHolder, AppealSignatureIconItself, AppealSignaturePart, AppealSignatureText, AppealText, AppealTitle } from "./Appeal.style"
import "./appeal.css";
import Modal from "../Modal/Modal";
import { useActions } from "../../hooks/actions";
import { useAppSelector } from "../../hooks/redux";




interface IEmptyFieldErrors {
  fin: string;
  mobile: string;
  isChecked: boolean | string;
  signature: string;
  amountValue: number | string;
  periodValue: string;
}




const Appeal = () => {
  const {showModal, sendModalName} = useActions();
  const {modalStatus} = useAppSelector(state => state.reducerAdditions);

  

  const [amountValue, setAmountValue] = useState<number>(2000);
  const [periodValue, setPeriodValue] = useState<string>("24");

  const initialValues: IEmptyFieldErrors = {
    fin: "",
    mobile: "",
    isChecked: false,
    signature: "",
    amountValue,
    periodValue,
  }

  const [inputFieldValue, setInputFieldValue] = useState<IEmptyFieldErrors>(initialValues);
  const [formErrors, setFormErrors] = useState<IEmptyFieldErrors>(initialValues);

  const inputRef = useRef<any>(null);

  const navigate  = useNavigate();

  useEffect(() => {
    const inputRefChildren = [...inputRef.current?.children];
    // console.log(inputRefChildren);

    const findPeriod = inputRefChildren.find(child => child.getAttribute("id") === periodValue)?.children[0];
    // console.log(findPeriod?.innerText);

    if (findPeriod?.innerText === periodValue) {
      inputRefChildren.forEach(each => each.children[0].classList.remove("active"));
      findPeriod?.classList.add("active");
    }
  }, [periodValue]);

  const inputValueChangeHandler = (e: any) => {
    const {name, value} = e.target;

    const onlyLettersAndNumbersRegex = /^[A-Za-z0-9]+$/;
    const onlyNumbersRegex = /^[0-9]*$/;

    if (
      (name === "fin" && (value === "" || value.match(onlyLettersAndNumbersRegex))) ||
      (name === "mobile" && (value === "" || value.match(onlyNumbersRegex)))
    ) {
      setInputFieldValue({
        ...inputFieldValue,
        [name]: value,
      });
    }
  }





  // useEffect(() => {
  //   console.log(amountValue);
  //   const last2Str = String(amountValue).slice(-2);
  //   let last2Num = Number(last2Str);
  //   console.log(last2Num);
  // }, [amountValue]);







  const inputRangeHandler = (e: any, typeFor: string) => {
    if (typeFor === "amount") {
      setAmountValue(parseInt(e.target.value, 10));
    }
    else if (typeFor === "period") {
      setPeriodValue(e.target.value);
    }
  }

  const inputBlurHandler = () => {
    if (+periodValue < 6 || (+periodValue > 6 && +periodValue < 9)) {
      setPeriodValue("6");
    }
    if (+periodValue >= 9 && +periodValue <= 14) {
      setPeriodValue("12");
    }
    if (+periodValue >= 15 && +periodValue <= 20) {
      setPeriodValue("18");
    }
    if (+periodValue >= 21 && +periodValue <= 26) {
      setPeriodValue("24");
    }
    if (+periodValue >= 27 && +periodValue <= 32) {
      setPeriodValue("30");
    }
    if (+periodValue >= 33) {
      setPeriodValue("36");
    }
  }

  const validate = (e: IEmptyFieldErrors) => {
    const emptyFieldErrors: IEmptyFieldErrors = {
      fin: "",
      mobile: "",
      isChecked: "",
      signature: "",
      amountValue: "",
      periodValue: "",
    }

    // console.log(emptyFieldErrors);

    if (e.fin === "") {
      emptyFieldErrors.fin = "Empty field error message";
    } else if (e.fin.length !== 7) {
      emptyFieldErrors.fin = "FIN length should be 7 characters!";
    }
    if (e.mobile === "") {
      emptyFieldErrors.mobile = "Empty field error message";
    } else if (e.mobile.length !== 9) {
      emptyFieldErrors.mobile = "Mobile length should be 9 characters!";
    }
    if (!e.isChecked) {
      emptyFieldErrors.isChecked = "Privacy Policy checkbox must be checked";
    }
    if (e.signature === "") {
      emptyFieldErrors.signature = "Choose one of the signature type";
    }

    return emptyFieldErrors;
  }

  // https://stackoverflow.com/questions/53715465/can-i-set-state-inside-a-useeffect-hook
  useEffect(() => {
    setInputFieldValue(inputFieldValue => ({
      ...inputFieldValue,
      amountValue: amountValue,
      periodValue: periodValue,
    }));
  }, [amountValue, periodValue]);

  const appealHandler = () => {
    setFormErrors(() => validate(inputFieldValue));
    // console.log(inputFieldValue);

    const {fin, mobile, isChecked, signature} = inputFieldValue;

    if (fin.length === 7 && mobile.length === 9 && isChecked === true && signature.length > 0) {
      // console.log(inputFieldValue);

      setAmountValue(2000);
      setPeriodValue("24");
      setInputFieldValue(() => ({
        fin: "",
        mobile: "",
        isChecked: false,
        signature: "",
        amountValue,
        periodValue,
      }));
      showModal(true);
      sendModalName("appeal_processing");

      setTimeout(() => {
        showModal(false);
        sendModalName(null);
        // showBadge(true);
        navigate('/offers');
      }, 5000);
    }
  };

  return (
    <>
      <AppealMainContainer>
        <AppealLeft>
          <AppealTitle>WELCOME TO THE DIGITAL BANK!</AppealTitle>
          <AppealText>A cash loan is offered to anyone with an official income. For the first time in the country, the loan granting process has been fully automated by our bank. The decision on your loan application is made within 30-60 seconds.</AppealText>
          <AppealButton className="appealButtonForDesktop" onClick={appealHandler}>Apply now</AppealButton>
        </AppealLeft>
        <AppealRight>
          <AppealInputContainer>
            <AppealInputLeft>
              <AppealRangeLabel>Amount</AppealRangeLabel>
              <AppealRangeItself
                type="range"
                min="500"
                max="3000"
                step="50"
                list="amount"
                value={amountValue ? amountValue : 0}
                onChange={(e) => inputRangeHandler(e, "amount")}
              />
              {/* <datalist id="amount">
                <option>500</option>
                <option>2000</option>
                <option>3000</option>
              </datalist> */}
              <AppealRangeScale>
                <AppealRangeMeasureLabel>500</AppealRangeMeasureLabel>
                <AppealRangeMeasureLabel>1 000</AppealRangeMeasureLabel>
                <AppealRangeMeasureLabel>1 500</AppealRangeMeasureLabel>
                <AppealRangeMeasureLabel>2 000</AppealRangeMeasureLabel>
                <AppealRangeMeasureLabel>2 500</AppealRangeMeasureLabel>
                <AppealRangeMeasureLabel>3 000</AppealRangeMeasureLabel>
              </AppealRangeScale>
              <AppealRangeCustomGap />
              <AppealRangeLabel>Term</AppealRangeLabel>
              <AppealRangeItself
                type="range"
                min="6"
                max="36"
                step="6"
                list="period"
                value={periodValue ? periodValue : 0}
                onChange={(e) => inputRangeHandler(e, "period")}
              />
              {/* <datalist id="period">
                <option>6</option>
                <option>12</option>
                <option>18</option>
                <option>24</option>
                <option>30</option>
                <option>36</option>
              </datalist> */}
              <AppealRangeScale ref={inputRef}>
                <div id="6"><AppealRangeMeasureLabel>6</AppealRangeMeasureLabel></div>
                <div id="12"><AppealRangeMeasureLabel>12</AppealRangeMeasureLabel></div>
                <div id="18"><AppealRangeMeasureLabel>18</AppealRangeMeasureLabel></div>
                <div id="24"><AppealRangeMeasureLabel className="active">24</AppealRangeMeasureLabel></div>
                <div id="30"><AppealRangeMeasureLabel>30</AppealRangeMeasureLabel></div>
                <div id="36"><AppealRangeMeasureLabel>36</AppealRangeMeasureLabel></div>
              </AppealRangeScale>
            </AppealInputLeft>
            <AppealInputRight>
              <div>
                <AppealInputLabel>Total amount</AppealInputLabel>
                <AppealInputItself
                  value={amountValue ? amountValue : 0}
                  onChange={(e) => inputRangeHandler(e, "amount")}
                  onBlur={() => amountValue < 500 ? setAmountValue(500) : amountValue > 3000 ? setAmountValue(3000) : amountValue}
                />
                <AppealInputExtraForEuro />
              </div>
              <div>
                <AppealInputLabel>Term</AppealInputLabel>
                <AppealInputItself
                  value={periodValue ? periodValue : 0}
                  onChange={(e) => inputRangeHandler(e, "period")}
                  onBlur={() => inputBlurHandler()}
                />
                <AppealInputExtraForPeriod>MO</AppealInputExtraForPeriod>
              </div>
            </AppealInputRight>
          </AppealInputContainer>
          <AppealPersonalDataInputContainer>
            <AppealPersonalDataInputPart>
              <AppealInputLabel>PIN</AppealInputLabel>
              <AppealPersonalDataInputField
                id="fin"
                maxLength={7}
                name="fin"
                value={inputFieldValue.fin}
                onChange={(e) => inputValueChangeHandler(e)}
                error={formErrors.fin}
                placeholder="PIN"
              />
            </AppealPersonalDataInputPart>
            <AppealPersonalDataInputPart>
              <AppealInputLabel>Mobile number</AppealInputLabel>
              <AppealPersonalDataInputField
                maxLength={9}
                name="mobile"
                value={inputFieldValue.mobile}
                onChange={(e) => inputValueChangeHandler(e)}
                error={formErrors.mobile}
              />
              <AppealPersonalDataInputExtra>+49</AppealPersonalDataInputExtra>
            </AppealPersonalDataInputPart>
          </AppealPersonalDataInputContainer>
          <AppealPrivacyContainer>
            <AppealPrivacyCheckbox
              type="checkbox"
              error={formErrors.isChecked}
              onChange={() => setInputFieldValue({
                ...inputFieldValue,
                isChecked: !inputFieldValue.isChecked,
              })}
            />
            <AppealPrivacyText
              error={formErrors.isChecked}
            >I agree to provide X bank with data about my loan history from "<span style={{fontWeight: "500"}}>Credit Bureau</span>", and collect and process information from "<span style={{fontWeight: "500"}}>Electronic Government Portal</span>" and other information sources.</AppealPrivacyText>
          </AppealPrivacyContainer>
          <AppealSignatureContainer>
            <AppealSignaturePart
              signature={inputFieldValue.signature}
              text="sima"
              onClick={() => setInputFieldValue({
                ...inputFieldValue,
                signature: "sima",
              })}
              error={formErrors.signature}
            >
              <AppealSignatureIconHolder>
                <AppealSignatureIconItself text="sima" />
              </AppealSignatureIconHolder>
              <AppealSignatureText>With e-sign</AppealSignatureText>
            </AppealSignaturePart>
            {/* <AppealSignaturePart
              signature={inputFieldValue.signature}
              text="asan"
              onClick={() => setInputFieldValue({
                ...inputFieldValue,
                signature: "asan",
              })}
              error={formErrors.signature}
            >
              <AppealSignatureIconHolder>
                <AppealSignatureIconItself text="asan" />
              </AppealSignatureIconHolder>
              <AppealSignatureText>With ASAN Signature</AppealSignatureText>
            </AppealSignaturePart> */}
          </AppealSignatureContainer>
          <AppealButton className="appealButtonForMobile" onClick={appealHandler}>Apply now</AppealButton>
        </AppealRight>
      </AppealMainContainer>

      {modalStatus && <Modal />}
    </>
  )
}

export default Appeal