import React from 'react'
import { ModalGreenCheckedIcon, ModalInfoText, ModalTitle } from "../../Modal/Modal.style"
import { ContractDocument, ContractDownloadIconHolder, ContractDownloadIconItself, ContractLeft, ContractMainContainer, ContractRight, ContractSignatureButton, ContractSignatureIconHolder, ContractSignatureIconItself, ContractSignaturePart, ContractSignatureSection } from "./Contract.style"

const Contract = () => {
  return (
    <>
      <ContractMainContainer>
        <ContractLeft>
          <ModalGreenCheckedIcon style={{marginBottom: "3vh"}} />
          <ModalTitle style={{marginBottom: "1.5vh"}}>Contract is ready.</ModalTitle>
          <ModalInfoText style={{width: "42vh !important", fontWeight: "500"}}>Click here to get the contract.</ModalInfoText>
          <ContractSignatureSection>
            <ContractSignaturePart>
              <ContractSignatureIconHolder>
                <ContractSignatureIconItself text="sima" />
              </ContractSignatureIconHolder>
              <ModalInfoText style={{fontWeight: "500"}}>With e-sign</ModalInfoText>
            </ContractSignaturePart>
          </ContractSignatureSection>
          <ContractSignatureButton>
            Sign the contract
          </ContractSignatureButton>
        </ContractLeft>
        <ContractRight>
          <ContractDocument>Contract</ContractDocument>
          <ContractDownloadIconHolder>
            <ContractDownloadIconItself />
          </ContractDownloadIconHolder>
        </ContractRight>
      </ContractMainContainer>
    </>
  )
}

export default Contract