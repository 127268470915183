import styled, { css } from "styled-components/macro";
import advantages_1_icon from "./../../assets/svg/advantages_1_icon.svg";
import advantages_2_icon from "./../../assets/svg/advantages_2_icon.svg";
import advantages_3_icon from "./../../assets/svg/advantages_3_icon.svg";
import advantages_4_icon from "./../../assets/svg/advantages_4_icon.svg";
import advantages_5_icon from "./../../assets/svg/advantages_5_icon.svg";

export const AdvantagesContainer = styled.section`
  font-family: Montserrat;
  margin-bottom: 29.1vh;
  @media (max-width: 768px) {
    position: relative;
    top: -4vh;
    margin-bottom: 8vh;
  }
`;

export const AdvantagesTitle = styled.h1`
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 5.5vh;
  color: #000000;
  margin-bottom: 6vh;
  @media (max-width: 768px) {
    font-size: 2.5vh;
    margin-bottom: 1.6vh;
  }
`;

export const AdvantagesFlexBoxSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2vh;
  margin: 0 15.2vh;
  @media (max-width: 768px) {
    justify-content: start;
    gap: 1.8vh;
    flex-wrap: wrap;
    margin: 0 2vh;
  }
`;

export const AdvantagesPart = styled.div`
  width: 29.7vh;
  height: 24vh;
  overflow-y: hidden;
  border: 0.1vh solid #00000080;
  border-radius: 0.8vh;
  padding: 3vh 3.4vh;
  @media (max-width: 768px) {
    width: 47.5%;
    height: 13.9vh;
    padding: 1.2vh;
  }
`;

export const AdvantagesIconHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10vh;
  height: 10vh;
  border-radius: 50%;
  background-color: #25398E14;
  margin-bottom: 1.5vh;
  @media (max-width: 768px) {
    width: 4vh;
    height: 4vh;
    margin-bottom: 1vh;
  }
`;

interface IAdvantagesIconItself {
  text: string;
}

export const AdvantagesIconItself = styled.div`
  background-repeat: no-repeat;
  ${(props: IAdvantagesIconItself) => props.text === "Əlavə sənədlər yoxdur" ?
    css`
      background-image: url(${advantages_1_icon});
      width: 4.88vh;
      height: 5vh;
      @media (max-width: 768px) {
        width: 2.4vh;
        height: 2.4vh;
      };
    ` : props.text === "Sürətli" ?
    css`
      background-image: url(${advantages_2_icon});
      width: 5vh;
      height: 5vh;
      @media (max-width: 768px) {
        width: 2.5vh;
        height: 2.5vh;
      };
    ` : props.text === "Zaminsiz" ?
    css`
      background-image: url(${advantages_3_icon});
      width: 6vh;
      height: 6vh;
      @media (max-width: 768px) {
        width: 2.4vh;
        height: 2.4vh;
      };
    ` : props.text === "Hesab açmadan" ?
    css`
      background-image: url(${advantages_4_icon});
      width: 6vh;
      height: 6vh;
      @media (max-width: 768px) {
        width: 2.4vh;
        height: 2.4vh;
      };
    ` : props.text === "Rahat müraciət" ?
    css`
      background-image: url(${advantages_5_icon});
      width: 6.5vh;
      height: 6.5vh;
      @media (max-width: 768px) {
        width: 2.4vh;
        height: 2.4vh;
      };
    ` : null}
`;

export const AdvantagesPartSubTitle = styled.h2`
  font-weight: 500;
  font-size: 2vh;
  margin-bottom: 1vh;
  @media (max-width: 768px) {
    font-size: 1.4vh;
  }
`;

export const AdvantagesPartText = styled.p`
  font-family: Montserrat !important;
  font-weight: 400 !important;
  font-size: 1.2vh;
  margin: 0 !important;
  @media (max-width: 768px) {
    font-size: 1.1vh;
  }
`;