import React from 'react'
import { AdvantagesContainer, AdvantagesIconHolder, AdvantagesIconItself, AdvantagesFlexBoxSection, AdvantagesPart, AdvantagesPartSubTitle, AdvantagesPartText, AdvantagesTitle } from "./Advantages.style"

const Advantages = () => {
  return (
    <>
      <AdvantagesContainer>
        <AdvantagesTitle>Advantages of online loan</AdvantagesTitle>
        <AdvantagesFlexBoxSection>
          <AdvantagesPart>
            <AdvantagesIconHolder>
              <AdvantagesIconItself text="Əlavə sənədlər yoxdur" />
            </AdvantagesIconHolder>
            <AdvantagesPartSubTitle>No additional documents</AdvantagesPartSubTitle>
            <AdvantagesPartText>No documents are requested on paper carrier.</AdvantagesPartText>
          </AdvantagesPart>
          <AdvantagesPart>
            <AdvantagesIconHolder>
              <AdvantagesIconItself text="Sürətli" />
            </AdvantagesIconHolder>
            <AdvantagesPartSubTitle>Fast</AdvantagesPartSubTitle>
            <AdvantagesPartText>You get the loan in just 30-60 seconds.</AdvantagesPartText>
          </AdvantagesPart>
          <AdvantagesPart>
            <AdvantagesIconHolder>
              <AdvantagesIconItself text="Zaminsiz" />
            </AdvantagesIconHolder>
            <AdvantagesPartSubTitle>No guarantor</AdvantagesPartSubTitle>
            <AdvantagesPartText>No guarantor is required.</AdvantagesPartText>
          </AdvantagesPart>
          <AdvantagesPart>
            <AdvantagesIconHolder>
              <AdvantagesIconItself text="Hesab açmadan" />
            </AdvantagesIconHolder>
            <AdvantagesPartSubTitle>Without opening an account</AdvantagesPartSubTitle>
            <AdvantagesPartText>No need to come to the bank. Everything is online.</AdvantagesPartText>
          </AdvantagesPart>
          <AdvantagesPart>
            <AdvantagesIconHolder>
              <AdvantagesIconItself text="Rahat müraciət" />
            </AdvantagesIconHolder>
            <AdvantagesPartSubTitle>Easy to apply</AdvantagesPartSubTitle>
            <AdvantagesPartText>You apply here, and you get the bank's offer right away.</AdvantagesPartText>
          </AdvantagesPart>
        </AdvantagesFlexBoxSection>
      </AdvantagesContainer>
    </>
  )
}

export default Advantages