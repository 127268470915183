import React from 'react'
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../hooks/redux"
import { NavbarContainer, NavbarHamburgerMenu, NavbarLanguage, NavbarLanguageIcon, NavbarList, NavbarLogoSection, NavbarMenuSection, NavbarNotificationBadge, NavbarNotificationBell, NavbarSignIn, NavbarSignUp, NavbarSignUpSection, NavbarUl } from "./Navbar.style"

const Navbar = () => {
  // const {badgeStatus} = useAppSelector(state => state.reducerAdditions);

  return (
    <>
      <NavbarContainer>
        <NavbarHamburgerMenu />
        <NavbarLogoSection>
          {/* <NavLink to={"/"}></NavLink> */}
        </NavbarLogoSection>
        <NavbarMenuSection>
          <NavbarUl>
            <NavbarList>Services</NavbarList>
            <NavbarList>
              {/* <NavLink to={"/offers"}> */}
                Offers
                {/* <NavbarNotificationBadge status={badgeStatus} /> */}
              {/* </NavLink> */}
            </NavbarList>
            <NavbarList>Accounts</NavbarList>
            <NavbarList>About us</NavbarList>
            <NavbarList>Contact</NavbarList>
          </NavbarUl>
        </NavbarMenuSection>
        <NavbarSignUpSection>
          <NavbarSignIn>Login</NavbarSignIn>
          <NavbarSignUp>Registration</NavbarSignUp>
        </NavbarSignUpSection>
        <NavbarNotificationBell />
      </NavbarContainer>
    </>
  )
}

export default Navbar