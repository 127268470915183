import React from 'react'
import { AboutImage, AboutLeft, AboutOfferButton, AboutRight, AboutSectionContainer, AboutText, AboutTitle } from "./About.style"

const About = () => {
  return (
    <>
      <AboutSectionContainer>
        <AboutLeft>
          <AboutTitle>About online credit</AboutTitle>
          <AboutText>Modern life is characterized by too much uncertainty - at any moment any of us may have problems that will require unplanned financial expenses. Applying to the bank means losing a lot of energy, time to collect the necessary documents, income certificates, communication with managers and specialists, long and tiring waiting for a decision. At the same time, it is not a fact that your loan application will be approved - banks do not serve users with a low credit rating, borrowers without proof of income, providing valuable movable or immovable property as collateral, recommendations from solvent guarantors. The easiest way out in such a situation is to apply for a loan through online channel.</AboutText>
          <AboutOfferButton>Order now</AboutOfferButton>
        </AboutLeft>
        <AboutRight>
          <AboutImage />
        </AboutRight>
      </AboutSectionContainer>
    </>
  )
}

export default About