import styled, { css } from "styled-components/macro";
import euro from "./../../assets/svg/euro_symbol.svg";
import simaImza from "./../../assets/svg/sima_imza.svg";
import asanImza from "./../../assets/svg/asan_imza.svg";
import checked from "./../../assets/svg/checked.svg";

export const AppealMainContainer = styled.section`
  position: relative !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5vh;
  height: 100% important;
  font-family: Montserrat;
  overflow: hidden;
  margin: 0 15.2vh 18vh 15.2vh;
  @media (max-width: 768px) {
    flex-direction: column;
    top: -7vh;
    gap: 13vh;
    margin: 0 2vh;
  }
`;

export const AppealLeft = styled.div`
  width: 37vw;
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 2vh;
  }
`;

export const AppealRight = styled.div`
  width: 44vw;
  @media (max-width: 768px) {
    position: relative;
    width: 100%;
    height: 74vh;
    overflow: hidden;
    border: 0.1vh solid #3A3A3A80;
    border-radius: 1vh;
    padding: 2.2vh;
  }
`;

export const AppealTitle = styled.h1`
  font-weight: 500;
  font-size: 6vh;
  color: #25398E;
  margin-bottom: 2vh;
  @media (max-width: 768px) {
    text-align: center;
    line-height: 4.2vh;
    font-size: 3vh;
    margin: 0 3vh;
  }
`;

export const AppealText = styled.p`
  font-family: Montserrat !important;
  font-weight: 400;
  font-size: 2vh;
  color: #3A3A3A;
  margin: 0 !important;
  margin-bottom: 5vh !important;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const AppealButton = styled.button`
  width: 34.4vh;
  height: 4.5vh;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 1.8vh;
  border-radius: 0.8vh;
  border: 0 solid transparent;
  background-color: #0058A0;
  color: #FFFFFF;
  cursor: pointer;
  @media (max-width: 768px) {
    position: relative;
    top: -22vh;
    width: 100%;
    font-size: 1.6vh;
  }
`;

export const AppealInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6.3vh;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0;
  }
`;

export const AppealInputLeft = styled.div`
  display: inherit;
  flex-direction: column;
`;

export const AppealInputRight = styled.div`
  position: relative;
  top: -1.8vh;
  display: flex;
  flex-direction: column;
  gap: 3.4vh;
  // margin-left: 7.3vh;
  padding-top: 0.5vh;
  @media (max-width: 768px) {
    top: -21vh;
    gap: 10vh;
  }
`;

export const AppealRangeLabel = styled.label`
  font-weight: 500;
  font-size: 2.2vh;
  margin-bottom: 1.4vh;
  @media (max-width: 768px) {
    font-size: 2vh;
  }
`;

export const AppealRangeItself = styled.input`
  width: 48vh;
  height: 0.8vh;
  appearance: none;
  overflow: hidden;
  border-radius: 0.8vh;
  margin-bottom: 0.7vh;
  z-index: 1;
  cursor: pointer;
  ::-webkit-slider-runnable-track {
    height: 0.8vh;
    width: 100%;
    cursor: pointer;
    pointer-events: none;
    // box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    background-color: #25398E4D;
    border-radius: 1vh;
  }
  &:focus {
    outline: none;
  }
  // &:focus::-webkit-slider-runnable-track {
  //   background: #25398E;
  // }
  &::-webkit-slider-thumb {
    appearance: none;
    height: 0.8vh;
    width: 0.8vh;
    background: #25398E;
    border-radius: 50%;
    box-shadow: -50.5vh 0 0 50vh #25398E;
  }
  &::-moz-range-thumb {
    appearance: none;
    height: 0.8vh;
    width: 0.8vh;
    background: #25398E;
    border: 0 solid transparent;
    border-radius: 50%;
    clip-path: inset(0 0 0 -47.5vh);
    box-shadow: -50.5vh 0 0 50vh #25398E;
  }
  &::-moz-range-track {
    height: 0.8vh;
    width: 100%;
    cursor: pointer;
    pointer-events: none;
    // box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    background-color: #25398E4D;
    border-radius: 1vh;
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 1.8vh;
    ::-webkit-slider-runnable-track {
      height: 1.8vh;
    }
    &::-webkit-slider-thumb {
      height: 1.8vh;
      width: 1.8vh;
      box-shadow: -50.5vh 0 0 49.5vh #25398E;
    }
    &::-moz-range-thumb {
      height: 1.8vh;
      width: 1.8vh;
    }
    &::-moz-range-track {
      height: 1.8vh;
    }
  }
`;

export const AppealRangeScale = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 48vh;
  margin-bottom: 3.5vh;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const AppealRangeCustomGap = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    height: 7vh;
  }
`;

export const AppealRangeMeasureLabel = styled.p`
  font-family: Montserrat !important;
  font-weight: 500;
  font-size: 1.5vh;
  color: #25398E;
  white-space: nowrap;
  margin: 0 !important;
`;

export const AppealInputLabel = styled.label`
  position: relative;
  top: 1vh;
  left: 1.6vh;
  display: inline-block;
  font-weight: 500;
  font-size: 1.2vh;
  background-color: #FFFFFF;
  color: #25398E;
  padding: 0 0.3vh;
`;

export const AppealInputItself = styled.input.attrs({
  type: "number",
  })`
  display: block;
  width: 21.8vh;
  height: 4.5vh;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 1.8vh;
  color: #3A3A3A;
  border: 0.1vh solid #25398E;
  border-radius: 0.4vh;
  text-indent: 1.2vh;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const AppealInputExtra = styled.div`
  position: relative;
  top: -3.3vh;
  right: 1.6vh;
  float: right;
  @media (max-width: 768px) {
    float: left;
    left: 18vh;
  }
`;

export const AppealInputExtraForEuro = styled(AppealInputExtra)`
  width: 1.6vh;
  height: 1.6vh;
  background-image: url(${euro});
  background-repeat: no-repeat;
`;

export const AppealInputExtraForPeriod = styled(AppealInputExtra)`
  font-weight: 500;
  font-size: 1.6vh;
  color: #3A3A3A;
`;

export const AppealPersonalDataInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2vh;
  margin-bottom: 2.4vh;
  @media (max-width: 768px) {
    position: relative;
    top: -22vh;
    flex-direction: column;
    gap: 0;
  }
`;

export const AppealPersonalDataInputPart = styled.div`
  @media (max-width: 768px) {
    height: 7vh;
    overflow: hidden;
  }
`;

interface IAppealPersonalDataInputField {
  name: string;
  error: string;
}

export const AppealPersonalDataInputField = styled.input`
  display: block;
  width: 34.2vh;
  height: 4.5vh;
  border: 0.1vh solid #25398E80;
  border-radius: 0.4vh;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 1.8vh;
  color: #3A3A3A;
  &::placeholder {
    font-weight: 500;
    color: #3A3A3A;
  };
  ${(props: IAppealPersonalDataInputField) => (props.name && props.error.length > 0) ?
    css`
      border: 0.1vh solid #ff554d;
      background-color: #ff554d33;
    ` : null
  }
  ${(props: IAppealPersonalDataInputField) => props.name === "fin" ?
    css`
      text-indent: 1.5vh;
    ` : props.name === "mobile" ?
    css`
      text-indent: 5.5vh;
    ` : null
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const AppealPersonalDataInputExtra = styled.div`
  position: relative;
  top: -3.3vh;
  left: 1.6vh;
  float: left;
  font-weight: 500;
  font-size: 1.8vh;
  color: #3A3A3A;
`;

interface IAppealPrivacyContainer {
  error: boolean | string;
}

export const AppealPrivacyContainer = styled.div`
  // display: flex;
  // align-items: start;
  display: -webkit-box;
  gap: 1.5vh;
  margin-bottom: 4.5vh;
  padding-left: 0.4vh;
  @media (max-width: 768px) {
    position: relative;
    top: -22vh;
    gap: 1vh;
    padding-left: 0;
  }
`;

const PrivacyCheckbox = styled.input`
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.6vh !important;
  height: 1.6vh !important;
  overflow-y: hidden;
  border-radius: 0.4vh !important;
  border: 0.1vh solid #25398E !important;
  cursor: pointer;
  &:after {
    display: none;
    content: url(${checked});
  }
  &:checked {
    background-color: #25398E;
    border: 0 solid transparent !important;
  }
  ${(props: IAppealPrivacyContainer) => props.error ?
    css`
      border: 0.1vh solid #ff554d !important;
    ` : null
  }
  @media (max-width: 768px) {
    width: 2vh !important;
    height: 2vh !important;
  }
`;

export const AppealPrivacyCheckbox = styled(PrivacyCheckbox)`
  &:checked:after {
    display: block;
  }
`;

export const AppealPrivacyText = styled.p`
  font-family: Montserrat !important;
  font-weight: 400;
  font-size: 1.4vh;
  line-height: 2vh;
  margin: 0 !important;
  ${(props: IAppealPrivacyContainer) => props.error ?
    css`
      color: #ff554d;
    ` : null
  }
  @media (max-width: 768px) {
    font-size: 1vh;
    line-height: 1.36vh;
  }
`;

export const AppealSignatureContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2vh;
  @media (max-width: 768px) {
    position: relative;
    top: -24vh;
    height: 5vh;
  }
`;

interface IAppealSignaturePart {
  signature: string;
  text: string;
  error: string;
}

export const AppealSignaturePart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 41.5vh;
  height: 7.8vh;
  border: 0.1vh solid #25398E80;
  border-radius: 0.8vh;
  cursor: pointer;
  ${(props: IAppealSignaturePart) => (props.signature === props.text) ?
    css`
      background-color: #25398E14;
      -webkit-box-shadow: inset 0px 0px 0.5vh #25398E14;
        -moz-box-shadow: inset 0px 0px 0.5vh #25398E14;
              box-shadow: inset 0px 0px 0.5vh #25398E14;
      outline: none;
    ` : props.error.length > 0 ?
    css`
      border: 0.1vh solid #ff554d;
    ` : null
  }
  @media (max-width: 768px) {
    height: 5vh;
    overflow: hidden;
    gap: 0.8vh;
    padding-left: 0.8vh;
  }
`;

export const AppealSignatureIconHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4vh;
  height: 4vh;
  overflow-y: hidden;
  border: 0.1vh solid #25398E80;
  border-radius: 50%;
  margin: 0 2.5vh;
  @media (max-width: 768px) {
    width: 2.6vh;
    height: 2.6vh;
    margin: 0vh;
  }
`;

interface IAppealSignatureIconItself {
  text: string;
}

export const AppealSignatureIconItself = styled.div`
  background-repeat: no-repeat;
  ${(props: IAppealSignatureIconItself) => props.text === "sima" ?
    css`
      background-image: url(${simaImza});
      width: 2.2vh;
      height: 2.2vh;
      @media (max-width: 768px) {
        width: 2.6vh;
        height: 2.6vh;
      };
    ` : props.text === "asan" ?
    css`
      background-image: url(${asanImza});
      width: 3.2vh;
      height: 2vh;
      @media (max-width: 768px) {
        width: 2.1vh;
        height: 1.3vh;
      };
    ` : null}
`;

export const AppealSignatureText = styled.p`
  font-family: Montserrat !important;
  font-weight: 500;
  font-size: 1.8vh;
  @media (max-width: 768px) {
    font-size: 1.4vh;
  }
`;