export const request = {
  post: async (url: string, bodyParams: object) => {
    const token: any = sessionStorage.getItem("credentials");
    const AccessToken = "Bearer " + JSON.parse(token).access_token || "";
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: AccessToken,
      },
      body: JSON.stringify(bodyParams),
    });
    if (response.status === 401) {
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = "/login";
    }
    return await response.json();
  },

  get: async (
    url: string,
    endpoint: string | null = null,
    id: number | null = null
  ) => {
    const token: any = sessionStorage.getItem("credentials");
    const AccessToken = "Bearer " + JSON.parse(token).access_token || "";
    let newUrl = id ? `${url + id}/` : url;
    const response = await fetch(newUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: AccessToken,
      },
    });
    if (response.status === 401) {
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = "/login";
    }
    return await response.json();
  },

  // patch: async (
  //   url: string,
  //   bodyParams: object,
  //   id: number | null = null,
  //   mode: string = ""
  // ) => {
  //   const token: any = sessionStorage.getItem("credentials");
  //   const AccessToken = "Bearer " + JSON.parse(token).access_token || "";
  //   let newUrl = id ? `${url + id}/` : url;

  //   const requestHeaders: HeadersInit = new Headers();
  //   requestHeaders.set("Accept", "application/json");
  //   requestHeaders.set("Content-Type", "application/json");
  //   requestHeaders.set("Authorization", AccessToken);

  //   mode && requestHeaders.set("Mode", mode);

  //   const response = await fetch(newUrl, {
  //     method: "PATCH",
  //     headers: requestHeaders,
  //     body: JSON.stringify(bodyParams),
  //   });
  //   if (response.status === 401) {
  //     sessionStorage.clear();
  //     localStorage.clear();
  //     window.location.href = "/login";
  //   }

  //   return await response.json();
  // },
  
  // delete: async (url: string, id: number | null = null) => {
  //   const token: any = sessionStorage.getItem("credentials");
  //   const AccessToken = "Bearer " + JSON.parse(token).access_token || "";
  //   let newUrl = id ? `${url + id}/` : url;
  //   const response = await fetch(newUrl, {
  //     method: "DELETE",
  //     headers: {
  //       Accept: "application/json",
  //       'Content-Type': "application/json",
  //       Authorization: AccessToken,
  //     },
  //   });
  //   if (response.status === 401) {
  //     sessionStorage.clear();
  //     localStorage.clear();
  //     window.location.href = "/login";
  //   }
  //   return response;
  // },
};
