import styled, { css } from "styled-components/macro";
import checkedGreen from "./../../assets/svg/checked_green.svg";

export const ModalDarkScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100%;
  // overflow-y: hidden !important;
  background-color: #3A3A3ACC;
  z-index: 1000;
`;

interface IModalContainer {
  text: string | null;
}

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: 10%;
  min-height: 10%;
  font-family: Montserrat;
  background-color: #FFFFFF;
  border-radius: 0.8vh;
  color: #3A3A3A;
  ${(props: IModalContainer) => props.text === "appeal_processing" ?
    css`
      text-align: center;
      width: 107.4vh;
      height: 89vh;
      padding: 2vh 3vh 4vh 2vh;
      @media (max-width: 768px) {
        padding-top: 1.2vh;
      }
    ` : props.text === "decline_appeal" ?
    css`
      padding: 2vh 3vh;
      gap: 2vh;
      @media (max-width: 768px) {
        gap: 2vh;
        padding: 3.4vh 2.9vh;
      }
    ` : null
  }
  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const ModalOfferSerial = styled.p`
  position: relative;
  right: 42vh;
  font-family: Montserrat !important;
  font-weight: 500;
  font-size: 1.8vh;
  margin: 0 !important;
  @media (max-width: 768px) {
    left: -11.5vh;
    font-size: 1.4vh;
  }
`;

export const ModalGreenCheckedIcon = styled.div`
  width: 6vh;
  height: 6vh;
  background-image: url(${checkedGreen});
  background-repeat: no-repeat;
`;

export const ModalTitle = styled.h1`
  font-weight: 600;
  font-size: 2.6vh;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

export const ModalInfoText = styled.p`
  width: 56vh;
  font-family: Montserrat !important;
  font-weight: 400;
  font-size: 1.8vh;
  margin: 0 !important;
  @media (max-width: 768px) {
    font-size: 15px;
    padding: 0 10vh !important;
  }
`;

export const ModalProcessingText = styled.h1`
  font-weight: 600;
  font-size: 3vh;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

// export const ModalProcessingIcon = styled.div`
//   width: 14vh;
//   height: 14vh;
//   background-image: url(${loading});
//   background-repeat: no-repeat;
// `;

export const CloseModalButton = styled.button`
  width: 27vh;
  height: 4.5vh;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 1.8vh;
  border: 0 solid transparent;
  border-radius: 0.8vh;
  background-color: #0058A0;
  color: #FFFFFF;
  cursor: pointer;
`;

export const ModalButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2vh;
  margin-bottom: 1vh;
  @media (max-width: 768px) {
    margin-bottom: 0;
  };
`;

const ModalButton = styled.button`
  width: 27vh;
  height: 4.5vh;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 1.8vh;
  border: 0.1vh solid #0058A0;
  border-radius: 0.8vh;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 17vh;
    font-size: 1.7vh;
  };
`;

export const ModalDeclineButton = styled(ModalButton)`
  background-color: #FFFFFF;
  color: #0058A0;
`;

export const ModalAcceptButton = styled(ModalButton)`
  background-color: #0058A0;
  color: #FFFFFF;
`;