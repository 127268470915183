import React from 'react'
import { FooterContainer, FooterCopyRightText, FooterList, FooterLogo, FooterLogoSection, FooterMenuSection, FooterSocialMediaIconHolder, FooterSocialMediaIconItself, FooterSocialMediaPart, FooterSocialMediaSection, FooterUl, FooterIconContainerForMobile, FooterIconItselfForMobile } from "./Footer.style"

const Footer = () => {
  return (
    <>
      <FooterContainer>
        <FooterLogoSection>
          <FooterLogo />
        </FooterLogoSection>
        <FooterMenuSection>
          <FooterUl>
            <FooterList>About bank</FooterList>
            <FooterList>Online services</FooterList>
            <FooterList>About us</FooterList>
            <FooterList>Contact</FooterList>
          </FooterUl>
        </FooterMenuSection>
        <FooterSocialMediaSection>
          <FooterSocialMediaPart>
            <FooterSocialMediaIconHolder>
              <FooterSocialMediaIconItself text="twitter" />
            </FooterSocialMediaIconHolder>
            <FooterSocialMediaIconHolder>
              <FooterSocialMediaIconItself text="facebook" />
            </FooterSocialMediaIconHolder>
            <FooterSocialMediaIconHolder>
              <FooterSocialMediaIconItself text="mail" />
            </FooterSocialMediaIconHolder>
            <FooterSocialMediaIconHolder>
              <FooterSocialMediaIconItself text="linkedin" />
            </FooterSocialMediaIconHolder>
          </FooterSocialMediaPart>
          <FooterCopyRightText style={{display: "none"}}>
            © 2022 Azərbaycan Mikro Kredit
          </FooterCopyRightText>
        </FooterSocialMediaSection>
      </FooterContainer>
      <FooterIconContainerForMobile>
        <FooterIconItselfForMobile text="home" />
        <FooterIconItselfForMobile text="payment" />
        <FooterIconItselfForMobile text="person" />
        <FooterIconItselfForMobile text="location" />
        <FooterIconItselfForMobile text="settings" />
      </FooterIconContainerForMobile>
    </>
  )
}

export default Footer